import { Config } from "config/config.type"
/** @firescoutMockVar Config.config */
const config:Config = {
  "env": "prod",
  "location": "lusini_nb-no",
  "shopName": "lusini",
  "locale": "nb-no",
  "rootCategoryId": "EMG",
  "availableCreditCards": [
    "MASTERCARD",
    "VISA"
  ],
  "orderMinimumB2C": 500,
  "links": {
    "contact": "/kundeservice/kontakt-oss/"
  },
  "i18n": {
    "currency_ISO": "NOK",
    "locale": "nb-NO",
    "country": "NO"
  },
  "slugifyLocale": "nb",
  "hrefLang": [
    {
      "configFolder": "lusini",
      "locale": "nb-no",
      "hrefLang": "nb"
    }
  ],
  "baseUrl": "https://www.lusini.com",
  "modules": {
    "onetrust": {
      "dataDomainScript": "bda0c016-94be-4fd9-943a-b9c09791a327"
    },
    "buzdev": {
      "errorUrl": "https://buz.lusini.com/webhook/com.lusini.commerce-platform/error/1-0-1"
    },
    "cloudinary": {
      "endpoint": "https://res.cloudinary.com/lusini/",
      "transformations": {
        "freisteller_l": "w_1500,h_1500,q_80,c_pad,f_auto",
        "freisteller_l_2": "w_1500,h_1500,q_80,c_pad,f_auto",
        "milieu_l": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "milieu_l_2": "w_1500,h_1500,g_auto,q_80,c_fill,f_auto",
        "freisteller_m": "w_500,h_500,q_70,c_pad,f_auto",
        "freisteller_m_2": "w_500,h_500,q_70,c_pad,f_auto",
        "milieu_m": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "milieu_m_2": "ws_500,h_500,g_auto,q_70,c_fill,f_auto",
        "freisteller_s": "w_300,h_300,q_70,c_pad,f_auto",
        "freisteller_s_2": "w_600,h_600,q_70,c_pad,f_auto",
        "milieu_s": "w_300,h_300,g_auto,q_70,c_fill,f_auto",
        "milieu_s_2": "w_600,h_600,g_auto,q_70,c_fill,f_auto",
        "freisteller_xs": "w_50,h_50,q_50,c_pad,fl_lossy,f_auto",
        "freisteller_xs_2": "w_100,h_100,q_50,c_pad,fl_lossy,f_auto",
        "milieu_xs": "w_50,h_50,g_auto,q_50,c_fill,fl_lossy,f_auto",
        "milieu_xs_2": "w_100,h_100,g_auto,q_50,c_fill,fl_lossy,f_auto"
      },
      "fallback_product_picture": "https://res.cloudinary.com/lusini/image/upload/v1643182695/application/products/lusini-online-shop-platzhalter-produktbild-in-kuerze-nb.svg",
      "newsletter_intentlayer_picture": "https://res.cloudinary.com/lusini/image/upload/v1724665736/application/NewsletterPopupAB/discount-reduction-nb-no.svg"
    },
    "shopware": {
      "salutations": [],
      "initialSaluationId": "6d0b33d96b5b400cbabc648c0a4c3f60",
      "accessKey": {
        "b2b": "SWSCEQXZLU5ZSB0I5UW0SY7EAA",
        "b2c": "SWSC7NJ5UZ5Y2UFQJDI8XMP3FZ"
      },
      "storefrontUrl": {
        "b2b": "https://www.lusini.com/nb-no/b2b",
        "b2c": "https://www.lusini.com/nb-no/b2c"
      },
      "apiUrl": "https://shop-api.lusini.com/store-api"
    },
    "algolia": {
      "apiKey": "bf4bf9193d5f34cd369647376521dbc8",
      "applicationId": "WAVYBEW6OQ"
    },
    "contentful": {
      "space": "aza65graowyr",
      "environment": "master",
      "accessToken": "OExq-bY8Ujs7M06rQEL1drC2kfafk-qmEzA8DZO0CCE",
      "previewToken": "QP7JxRhc0mYWGrX3I8vj9WdBO_eF7n9r0xnmcX11HAw"
    },
    "dyHybrid": {
      "apiKey": "a72829a6281955d5f8febc70eeb0a8de8a9f4841db410f4012d1fb4bf2dc5ffa",
      "campaignID": "9879544"
    },
    "trustShop": {
      "placeholderImg": "https://res.cloudinary.com/lusini/image/upload/c_scale,w_100/v1670412947/application/icons/trygg-e-handel.svg",
      "id": "1150",
      "url": "https://www.tryggehandel.no/?cid=",
      "urlPostfix": "&logo=1"
    },
    "freshchat": {
      "token": "77c43185-76a1-414d-a4c2-23079e3b4967",
      "host": "https://lusini.freshchat.com",
      "widgetUuid": "9ec8f13b-ae14-4be4-982f-cff2aa346112",
      "locale": "no"
    },
    "zenloop": {
      "id": "Tm1Wa01XTmtZMkV0WlRjeE5pMDBOVGc1TFRsaU16TXRaVEZsWkdVME1HRTJNR1kw"
    },
    "hotjar": {
      "id": 3549008,
      "version": 6
    }
  },
  "index": {
    "products": "prod_lusini_nb_NO_products",
    "productsPriceAsc": "prod_lusini_nb_NO_products_priceAsc",
    "productsPriceDesc": "prod_lusini_nb_NO_products_priceDesc",
    "categories": "prod_lusini_nb_NO_categories",
    "series": "prod_lusini_nb_NO_series",
    "productsSuggestion": "prod_lusini_nb_NO_products_query_suggestions",
    "productsLatest": "prod_lusini_nb_NO_products_noveltiesAsc",
    "productReviews": "prod_lusini_product_reviews"
  },
  "deliveryDate": {
    "shippingTooLong": 200
  },
  "colors": {
    "Hvit": "white",
    "Svart": "black",
    "Grå": "grey",
    "Sølvfarge": "silver",
    "Brun": "brown",
    "Blå": "blue",
    "Flerfarget": "multicolor",
    "Rød": "red",
    "Beige": "beige",
    "Grønn": "green",
    "Transparent": "transparent",
    "Gul": "yellow",
    "Gullfarge": "gold",
    "Oransje": "orange",
    "Rosa/lilla": "lilac"
  },
  "features": {
    "devMode": false,
    "acceptanceOfTermsRequiredForOrderingB2C": true,
    "acceptanceOfTermsRequiredForOrderingB2B": true,
    "trustShop": false,
    "zenloop": true,
    "qrCode": false,
    "b2c": true,
    "notificationBar": true,
    "showNewsletterIntentLayer": true,
    "userLikeWidgetUrl": "https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/4f39d06e10a026aae5d280092f9e8bf6ea6644095ddc65f64079581c5fb44c37.js",
    "freshchat": true,
    "customizableUrl": "https://www.lusini.com/nb-no/kundeservice/service/profilering/",
    "b2cHomePage": true,
    "showProductRecommendationInfoTextB2B": true,
    "showCartInfoboxFrom": "2024-12-12T08:00:00",
    "showCartInfoboxTo": "2024-12-21T01:00:00"
  },
  "formApi": {
    "conformityDeclaration": "https://getform.io/f/aec1a29b-dcdf-401c-801e-add63d6683bf"
  },
  "abTests": {},
  "showTelephoneContactOnFailedCheckoutThreshold": 0
}
export default config